import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify';
import configs from '@/configs.js';

Vue.use(VueAxios, axios)

Vue.config.productionTip = false
Vue.prototype.$baseUrl = configs.baseUrl;
Vue.prototype.$title = configs.title;
Vue.prototype.$color = configs.color;
Vue.prototype.$dark = configs.dark;
Vue.prototype.$consumerKey = configs.consumerKey;
Vue.prototype.$consumerSecret = configs.consumerSecret;

axios.defaults.baseURL = `${configs.baseUrl}/wp-json/`;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')