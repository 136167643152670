<template>
  <div>
    <v-progress-linear :color="color" indeterminate height="6" v-if="loading"></v-progress-linear>
    <v-container>
      <v-row>
        <v-col v-for="product in products" :key="product.id" cols="12" sm="6" lg="4">
          <v-card class="mt-3 mb-3 mx-auto" outlined :elevation="3">
            <v-img height="350" :src="product.images[0].src" alt="product.name" v-if="product.images.length > 0"
              class="black">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align-content="center" justify="center">
                  <v-progress-circular indeterminate :color="color"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-list-item three-line>
              <v-list-item-content>
                <v-row>
                  <v-col class="mx-0" v-for="categorie in product.categories" :key="categorie.slug">
                    <v-btn v-if="!!categorie" :href="`${baseUrl}categoria-producto/${categorie.slug}`" target="_blank"
                      text>
                      <span class="overline">
                        {{ categorie.name }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
                <a :href="`${baseUrl}producto/${product.slug}`" target="_blank">
                  <v-list-item-title class="headline ml-1 mb-1">
                    {{ product.name }}
                  </v-list-item-title>
                </a>
                <Variations :variations="product.variations" />
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear :color="color" indeterminate height="6" v-if="!first && loading"></v-progress-linear>
      <v-btn v-if="!first" class="my-2" :loading="loading" :disabled="loading || noMore" :color="color" @click="loadMore"
        block dark>
        Cargar más
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import configs from '@/configs.js';
import Variations from "./Variations.vue";
// TODO: mover api a un servicio aparte
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

export default {
  name: "Products",
  components: {
    Variations,
  },
  data: () =>
  ({
    color: configs.color,
    dark: configs.dark,
    baseUrl: configs.baseUrl,
    api: new WooCommerceRestApi({
      url: configs.baseUrl,
      consumerKey: configs.consumerKey,
      consumerSecret: configs.consumerSecret,
      version: "wc/v3",
      queryStringAuth: true,
    }),
    products: [],
    page: 1,
    per_page: 10,
    first: true,
    noMore: false,
    loading: false,

  }),
  created() {
    this.getProducts();
  },
  methods: {
    async loadMore() {
      this.page += 1;
      this.getProducts();
    },
    async getProducts() {
      this.loading = true;
      let products;
      try {
        products = await this.api.get("products", {
          page: this.page,
          per_page: this.per_page,
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
        return;
      }
      if (products.data.length < this.per_page) this.noMore = true;
      let ps = [...products.data];
      for (let i = 0; i < ps.length; i++) {
        let p = ps[i];
        ps[i].variations = await this.getVariations(p.id);
        ps[i].active = true;
      }
      this.products = [...this.products, ...ps];
      this.loading = false;
      this.first = false;
    },
    async getVariations(id) {
      try {
        const variations = await this.api.get(`products/${id}/variations`, {
          per_page: "100",
        });
        return variations.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
