<template>
  <v-app>
    <v-app-bar app :color="this.$color" dark>
      <div class="d-flex align-center">{{ this.$title }} | Catálogo</div>

      <v-spacer></v-spacer>

      <v-btn :href="this.$baseUrl" target="_blank" text>
        <span class="mr-2">Ir a la web</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>